import React from 'react';
import styled from 'styled-components';
import Logo from '../atoms/Logo';
import GenericCopy from '../molecules/GenericCopy';
import SocialIcons from '../molecules/SocialIcons';

const Holder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 0;
  min-height: 100vh;
  @media(${props => props.theme.breakpoints.md}) {
    position: absolute;
    padding: 0;
    width: 50%;
    top: 0;
    left: 0;
    margin: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;

const Inner = styled.div`
  width: 60%;
  @media(${props => props.theme.breakpoints.md}) {
    width: 30rem;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const TextHolder = styled.div`
  text-align: justify;
`;

function Copy( { children } ) {
  return (
    <Holder>
      <Inner>
        <Logo/>
        <TextHolder>
          {children}
        </TextHolder>
        <GenericCopy/>
        <SocialIcons/>
      </Inner>
    </Holder>
  )
}

export default Copy;
