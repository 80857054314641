import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import MarkdownConverter from '../atoms/MarkdownConverter';

const Holder = styled.div`
  margin-top: 4rem;
  text-align: justify;
  @media(${props => props.theme.breakpoints.md}) {
    margin-top: 0;
  }
`;

function GenericCopy() {
  const data = useStaticQuery( graphql`
      query GenericCopyQuery {
          contentfulGlobalSettings(title: {eq: "Global Settings"}) {
              commonPageText {
                  commonPageText
              }
          }
      }
  ` );

  return (
    <Holder>
      <MarkdownConverter content={data.contentfulGlobalSettings.commonPageText.commonPageText}/>
    </Holder>
  )
}

export default GenericCopy;
